import { Text } from '@workos-inc/component-library';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const Auth0CreateApplication: FC<Readonly<ConnectionStepProps>> = ({
  appName,
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      In your Auth0 Dashboard, select Applications {'>'} Applications from the
      sidebar. Then select "Create Application".
    </Text>

    <Img
      className="my-8"
      height={884}
      src="/images/94f7fd8e-5c33-4f19-8fdb-2c5964ea8e72.png"
      width={1446}
    />

    <Text>
      Enter an "Application Name" that describes {appName}, select the "Regular
      Web Applications" type, then click "Create".
    </Text>

    <Img
      className="my-8"
      height={884}
      src="/images/457fb7eb-d032-4fd4-a95c-8a8354454b26.png"
      width={1446}
    />

    <Text>
      Select the "Addons" tab in your new application, and then open the SAML2
      WEB APP menu.
    </Text>

    <Img
      className="my-8"
      height={884}
      src="/images/8686dddb-3e43-4875-8551-d574c6aa307f.png"
      width={1446}
    />

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      Enter the ACS URL provided above in the "Application Callback URL" input
      box.
    </Text>

    <Img
      className="my-8"
      height={1630}
      src="/images/e9cce2aa-eb53-46b0-a810-af43c74e6018.png"
      width={2776}
    />

    <Text>
      Scroll down to the bottom of the AddOn modal and select "Enable".
    </Text>

    <Img
      className="my-8"
      height={922}
      src="/images/704c01ab-ed81-4ba3-bc60-baba1dfc4965.png"
      width={1478}
    />

    <Confirm
      label="I've configured the ACS URL in the SAML application."
      onClick={handleNextStep}
    />
  </>
);

export const Auth0UploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://mycompany.us.auth0.com/samlp/AbC123..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      On the application's Settings page, scroll down to the bottom and expand
      the "Advanced Settings" section. Select the "Endpoints" tab and copy the
      SAML Metadata URL and enter it in the field below.
    </Text>

    <Img
      className="my-8"
      height={955}
      src="/images/5e1c43ed-6df9-4a7b-98ae-ce3130d263b1.png"
      width={1524}
    />
  </MetadataUrlStep>
);
